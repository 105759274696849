.App {}

button {
  /* padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent; */
  /* отображаем курсор в виде руки при наведении; некоторые
  считают, что необходимо оставлять стрелочный вид для кнопок */
  /* cursor: pointer; */
}

body {
  font-family: mulishregular !important;
  font-weight: normal;
  font-size: 20px !important;
  /* overflow: hidden; */
}

p {
  /* max-width: 1024px; */
  width: 100%;
  word-break: break-word;
}

img {
  /* width: 100%; */

  max-width: 100%;



}

h1 {
  margin-top: 0px;

}

.section_item_container {
  position: relative;
}

.menu_img {
  max-width: 3rem;
  width: 100%;
}

.image-uploading img {
  filter: blur(0px) !important;
  opacity: 1 !important;
}

.image-uploading::before {
  display: none;
}

.main_container {
  margin-right: 3.125rem;
  margin-left: 3.125rem;
}

.section_name_add-article {
  margin-top: 1.87rem;
}

.sections_container-articles_name {
  margin-bottom: 36px !important;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.login_page {
  background-color: #002456;
  background-image: url("/public/bgLoginPage3.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;


}

.login_page_logo {
  margin-top: 15px;
  position: absolute;
  top: 0;
  left: 342px;
}

.main_page_logo {
  margin-top: 3.125rem;
  margin-bottom: 1.125rem;
}

.main_page_title_text {
  font-family: mulishregular;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 2.18rem;
  margin-left: 4.87rem;

}

.select_button:focus {
  outline: #4A7BAB;
  /* border-color: #4A7BAB; */
  border: 2px solid rgba(2, 43, 148, 1);

}

.login_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 50px;
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  background-color: #F3F3F3;
  border-radius: 15px;
  margin-top: 13rem;
 width: 467px;
  /* width: 100%; */
  margin-bottom: 15rem;
  border: 1px solid rgba(2, 43, 148, 1);


}
.login_form-body{
  width: 100%;
}

.slider-media-content_video {
  max-height: 500px;
  height: 100%;
}

.login_form_button-container-submit {
  text-align: center;
  /* width: 290px; */
}

.login_form_button-container {
  text-align: center;
}

.error-message_login {
  color: #FF4B4B;
}

.login_form_title {
  font-family: nunito_semibold;
  font-size: 26px;

  margin-bottom: 45px;
  text-align: center;
}

.select_button {
  border: 1px solid rgba(149, 149, 149, 1);

  border-radius: 5px;
  background-color: #E3EBFF !important;
  color: #181818;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
  /* max-width: 290px; */
  width: 100%;
  text-indent: 15px;
  font-family: nunito;
  font-size: 20px;
  /* outline: none; */
}
.select_button::placeholder{
  color: #959595;
}
/* Удаление иконки автозаполнения в Chrome, Safari и других браузерах на WebKit */
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
}

/* Удаление иконки автозаполнения в Firefox */
input::-moz-list-bullet {
  display: none;
}


.select_button:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #E3EBFF inset !important;
 
}
.select_button:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #E3EBFF inset !important;
  border: 2px solid rgba(2, 43, 148, 1);
  position: relative; /* добавляем позиционирование */
}
.select_button:-webkit-autofill:focus::before {
  content: "";
  position: absolute;
  top: 2px; /* отступ сверху */
  left: 2px; /* отступ слева */
  right: 2px; /* отступ справа */
  bottom: 2px; /* отступ снизу */
  border: 1px solid rgba(2, 43, 148, 1); /* цвет внутренней рамки */
  z-index: -1; /* чтобы внутренняя рамка была под основной рамкой */
}
.password-input-wrapper{
  position: relative;
  z-index: 1;
}
.show-password{
  position: absolute;
  z-index: 2;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}
.error-popup {
  position: fixed;
  bottom:-100px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FF5A5A;
  color: white;
  padding: 15px 20px;
  border-radius: 10px;
  display: flex;

  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  z-index: 1000;
  max-width: 1236px;
  width: 100%;
  justify-content: center;
  transition: bottom 0.5s ease-in-out; /* Плавный переход */
}
.error-popup.show {
  bottom: 80px; /* Конечное положение */
}

/* Скрыть всплывающее окно */
.error-popup.hide {
  bottom: -100px; /* Положение ниже видимой области */
}

.error-icon {
  margin-right: 10px;
}

.error-icon img {
  width: 29px;
  height: 29px;
}

.error-text {
  font-family: nunito;
  font-size: 24px;
}

.select_button_password_margin {
  margin-top: 40px;
  margin-bottom: 45px;
}


.form_button_submit {
  font-family: mulishregular;
  background-color: #4A7BAB;
  color: #fff;
  font-weight: normal;
  font-style: 16px !important;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 103px;
  padding-right: 103px;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
}

.modal_alert-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 37.5rem;
  width: 100%;
  background-color: #D0E5FD;
  text-align: center;

}
.registration-link {
  margin-top: 45px;
  
}

.registration-link a {
  color: #022B94;
  text-decoration: none;
  font-size: 18px;
  font-family: nunito;
  border-bottom: 1px solid #022B94;
}

.registration-link {
  text-decoration: none;
}

.modal-actions_buttons {
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 20px;
  background-color: #79A0CC;
  font-family: mulishregular;
}

.modal_alert-text {
  font-size: 40px;
  font-family: mulishregular;
  margin-top: 3.75rem;
}

.modal-actions_buttons_red {
  background-color: #FA9191;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-left: 7.5rem;
  margin-right: 7.5rem;
  margin-top: 2.65rem;
  margin-bottom: 3.75rem;
}

.form_button_submit:hover {
  box-shadow: inset 2px 2px 10px rgba(0, 0, 0, 0.25);
  transition: 0.5s;
}


.button_body {
  background-color: #355E8B;
  cursor: pointer;
  max-width: 15.3rem;
  /* height: 5.8rem; */
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.18rem;
  overflow: hidden;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;

}

.active {
  /* background-color: #668AB0 !important;
  box-shadow: inset 0px 4px 0px 0px rgba(0, 0, 0, 0.25) !important;
  transition: 0.5s !important; */

}

.section_plus {
  background-image: url("/public/note-favorite.svg");
  max-width: 48px;
  height: 48px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
}


.button_body:hover {
  box-shadow: inset 2px -1px 8px 10px rgba(0, 0, 0, 0.25);
  transition: box-shadow 0.5s;

}



.button_text {
  /* white-space: nowrap; */
  padding-top: 10px;
  color: #fff;
  font-family: mulishregular;
  font-weight: light;
  font-size: 16px;
  text-overflow: ellipsis;
  max-width: 10rem;
  overflow: hidden;



}

.modal-background {
  width: 100%;
  height: 100%;
}

.modal_main-window {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(5px);
  border: 1px solid black;
  padding: 20px;
  height: 100%;
  width: 100%;
  z-index: 201;
}

.form_modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 66.128rem;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  border: 1px solid #355E8B;
  border-radius: 5px;
}

.form_title {
  font-family: mulishregular;
  font-size: 20px;
  color: #000;
  font-weight: normal;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal_form-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form_button_container {
  max-width: 245px;
  width: 100%;
}

.form_button {
  font-family: mulishregular;
  text-decoration: none;
  border: none;
  background-color: #79A0CC;
  color: #000;
  font-size: 20px;
  max-width: 245px;
  width: 100%;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 5px;
}

.main_page_container {
  padding-right: 3.12rem;
  padding-left: 3.12rem;
  padding-top: 3.12rem;
}

.main_page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.main_page_container_custom {
  display: flex;
  flex-direction: row;

}

.menu_container {
  display: flex;
  flex-direction: row;
  margin-top: 3.12rem;
}

.menu_container_right {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.sections_container {
  display: flex;
  flex-direction: column;

  width: 17.75rem;

  /* margin-top: 10.81rem; */
  margin-bottom: 30px;
}

.subsection_button_content {
  display: flex;
  width: 100%;
}




.subsections_container {

  width: 100%;
  margin-bottom: 30px;


}

.section_button_content {
  display: flex;
  justify-content: start;
}

.article_container {
  /* margin-left: 50px; */
  width: 100%;
  display: flex;
  /* margin-top: 10.81rem; */
  flex-direction: row;


  background-color: #D0E5FD
}

.menu_container_left {
  max-width: 245px;
  width: 100%;
  margin-right: 30px;
  position: relative;
  z-index: 1;
}

.article_button_container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1236px;
  width: 100%;
  margin: auto;
  /* margin-right: 21.375rem;
  margin-left: 21.375rem; */
  padding-top: 14px;
  align-items: center;
}

.logout_button {
  border-radius: 5px;
  width: 100px;
  height: 20px;
  align-items: center;
  margin-top: 0px;
  background-color: #355E8B !important;
}

.logout_button:hover {
  box-shadow: inset 2px -1px 8px 10px rgba(0, 0, 0, 0.25) !important;
  transition: box-shadow 0.5s;
}

.logout_button_text {
  margin: auto;
  color: #FFF;
  line-height: 0;
}

.scroll-button {
  background: none;
  border: none;
  cursor: pointer
}

.section_button {
  position: relative;
  width: 17.75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #79A0CC;
  cursor: pointer;
}

.modal-editor {
  padding: 40px;
}

.delete_button_article {
  top: 5px !important;
  right: 40px !important;
}

/* .button_delete-container {
  position: absolute;
  top: 5px;
  left: 5px;
} */

.scroll-img {
  width: 46px;
}

.depart_list-block {
  margin-bottom: 25px;
}

.header_service-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 79px;
}

.form_menu_label_name {
  padding-bottom: 14px;
  padding-top: 20px;
}

.form_menu_input {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  border: 1px solid #000;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 10px;
  margin-top: 10px;


}

.form_menu_input-image_add {
  display: none;
}

.form_menu_label_img {
  max-width: 28px;
  width: 100%;

  height: 28px;
}

.form_menu_label_img-container {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 300px;

  height: 28px;
  display: flex;
  flex-direction: row !important;
}

.form_menu_label_img-text {
  margin-left: 8px;
}

.form_menu_input-image {
  background-image: url("../public/imageadd.svg");
  background-repeat: no-repeat;
  background-position: center;
  max-width: 28px;
  width: 100%;
  max-height: 28px;
  height: 100%;
}

.button_update-container {
  position: absolute;

  top: 0;
  right: 50px;
  transform: translate(0, 50%);
}

.menu_item {
  position: relative;


}

.form_menu_label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.edit_menu_button_container {
  width: 100%;
  max-width: 24px;
  height: 24px;
  position: absolute;
  top: 5px;
  right: 5px;

}

figure {
  width: 200px;
  margin: 0;
}

.edit_menu_button {
  max-width: 28px;
  width: 100%;
  max-height: 28px;
  height: 100%;
  cursor: cell;
  background-image: url("../public/editico.svg");
  background-repeat: no-repeat;
}

.rdw-image-center {
  justify-content: flex-start !important;
}







.section_button_add {
  padding-left: 40px;
  /* margin-top: 25px; */
  background-image: url("../public/add-square.svg");
  background-repeat: no-repeat;
  background-position: 95%;
  width: 15.31rem;


}

.section_button:hover {
  background-color: #668AB0;
  box-shadow: inset 0px 4px 0px 0px rgba(0, 0, 0, 0.25) !important;
  transition: 0.5s;
}

.section_img_container {
  /* margin-left: 1.68rem;
  margin-right: 1.68rem; */
  width: 40px;
  text-align: center;
  display: flex;
}

.section_button_container {
  display: flex;
  flex-direction: row;
}

.section_button_edit {

  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 10px;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  right: 10px;

}

.article_content_container-name {
  word-break: break-all;
}

.edit_menu_button-black {
  background-image: url("../public/editblack.svg");
  background-size: contain;
  background-position: center;
  width: 28px;
  height: 28px;
}

.article_content {
  position: relative;
  padding-left: 1.06rem;
  padding-right: 10px;
  min-width: 42.5rem;
}

.add_files {
  max-width: 28px !important;
  max-height: 28px !important;
  margin-right: 5px;
  margin-left: 5px;

}

.form_menu_input-depart {
  width: 284px;
  margin-top: 0;
  margin-right: 10px;

}

.form_modal-buttons_container {
  display: flex;
  flex-direction: column;
}

.form_menu_input-download_file {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.files_title {
  margin-bottom: 10px;
}

.file_name-download {
  cursor: pointer;
  font-style: italic;
  display: inline-block;
  margin-bottom: 10px;
  color: #0645AD;
}

.file_name-download:hover {
  text-decoration: underline;
}


.files_container {
  padding-left: 20px;
  padding-right: 20px;
}

.add_files-button {
  width: 28px;
  height: 28px;
  background-image: url("../public/imageadd.svg");
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.article_service-buttons {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

}

/* .delete_button {
  position: absolute !important;
  top: 0px;
  right: -10px;
  z-index: 100 !important;
} */

.delete_button-menu {
  position: absolute !important;
  top: 5px !important;
  left: 5px;
}

.cl-btn-4 {
  width: 252px;
  height: 39px;
  box-shadow: 0px 10 10px 10px rgba(0, 0, 0, 0.25);
  /* border-radius: 6px; */
  background: #FA9191;
  text-align: center;
  position: absolute;
  line-height: 35px;
  top: 0;
  right: 0;
  display: block;
  z-index: 0;
  /* text-indent: -9999px; */
  cursor: pointer;
}

.section_name {
  max-width: 200px;

  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
}

.subsection_name {
  width: 100%;


  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.section_img_container-articles {
  width: 0px;
  padding-left: 8px;
  padding-right: 8px;
}

.error_message {
  text-align: center;
  background-color: red;
  color: #fff;
}

.container_position_col {
  display: flex;
  flex-direction: column;

}

.section_img {
  max-width: 25px;
  width: 100%;
  padding-left: 11px;
}

.error-message {
  background-color: red;
  text-align: center;
  color: #fff;
  margin-bottom: 10px;

}

.edit_editor-article-container {
  width: 30px;
  height: 24px;


}

.delete_button_position_container {
  position: relative;
}


.slider_img_container {
  max-width: 12.5rem;
  min-width: 196px;
  width: 100%;
  height: 145px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
}

.slider_container_background {
  background-color: #001854;
  margin-top: 25px;
}



.slider_title {
  color: #fff;
  font-family: nunito_semibold;
  font-size: 16px;
}

.slider_img {
  max-width: 12.5rem;
  width: 100%;
}

.slick-slider {
  max-width: 77.25rem;
  margin: auto;
}

.slick-slide {
  max-width: 412px !important;
}

.slick-list {
  padding-left: 50px !important;
}

.slick-slider::before {
  content: '';
  position: absolute;
  top: 0;
  left: -50px;
  width: 100px;
  /* Ширина сплошной заливки */
  height: 100%;
  background-color: #001854;
  /* Цвет заливки */
  pointer-events: none;
  /* Элемент не будет влиять на события мыши */
  z-index: 1;
}

.slick-slider::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  /* Ширина градиентной полосы */
  height: 100%;
  background: linear-gradient(to left, rgba(0, 24, 84, 1), rgba(0, 24, 84, 0));
  /* Пример градиента */
  pointer-events: none;
  /* Элемент не будет влиять на события мыши */
}

.slick-next {
  width: 36px !important;
  height: 36px !important;
  right: 0px !important;
  z-index: 1;
}



.slick-current {
  /* padding-left: 40px; */
}

.news_title_content {
  font-size: 26px !important;
  font-family: nunito_semibold;
  margin-right: 15px;
  cursor: pointer;
}
.news_title_content:hover {
color: #022B94;  
}

.news_date {
  font-size: 20px;
  font-family: nunito;
}



.slick-prev {
  width: 36px !important;
  height: 36px !important;
  left: 0px !important;
  z-index: 1;
}

.slick-next:before {
  font-size: 36px !important;
  opacity: 1 !important;
}

.slick-prev:before {
  font-size: 36px !important;
  opacity: 1 !important;
}

.slider_container {
  display: flex !important;
  flex-direction: row;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;

}

.slick-arrow {
  fill: rgba(250, 250, 250, 1);
}


.slick-disabled {
  /* display: none !important; */
  fill: rgba(125, 137, 167, 1);
}

.button_article-editor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.form_button_submit-font_size {
  font-size: 18px !important;
  width: 100%;
}

.container_position_col-sub {
  margin-left: 30px;
  margin-right: 30px;
}

.main_news_container {
  background-image: url("../public/Mainb.svg");
  background-attachment: fixed;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;


}

.main_news-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-top: 30px; */
  height: 100vh;
  max-width: 1236px;
  width: 100%;
  margin: auto;


}

.big_news_container {
  max-height: 705px;
  overflow-y: auto;
  scrollbar-width: none;

}

.media_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.small_media_container {
  display: flex;
  justify-content: space-between;
  width: 694px;
  /* Ширина контейнера со всеми маленькими изображениями */
}

.news_title {
  font-family: nunito_semibold;
  font-size: 26px;
  text-transform: uppercase;
}

.news_main-content_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.full_news_img-big {
  width: 694px;
  height: 394px;
}

.news_item {
  display: flex;
  flex-direction: row;
  max-width: 60.25rem;
  border-bottom: 1px solid #959595;
  padding-bottom: 10px;
  padding-top: 10px;
  justify-content: space-between;
}

.partners_container {
  max-width: 13.93rem;
  width: 100%;
  margin-left: 50px;
  display: flex;
flex-direction: column;
}

.news_item_row {

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.news_img {
  max-width: 196px;
  width: 100%;
  height: 140px;
}

.header_menu-container {
  max-width: 27.625rem;
  width: 100%
}

.container-partners_main {
  padding-left: 0 !important;
  padding-right: 0 !important;
  height: 705px;
  overflow-y: auto;
  scrollbar-width: none;
}

.header_menu {
  display: flex;
  max-width: 27.625rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.heder_menu-object {
  font-family: nunito_medium;
}

.heder_menu-object:hover,
.heder_menu-object:focus,
.heder_menu-object:active {
  /* Добавляем :active для стиля при нажатии */
  color: #022B94;
  /* Изменение цвета текста при наведении, фокусе и активации */
}

.heder_menu-object:after {
  background-color: #022B94;
  display: block;
  content: "";
  height: 2px;
  width: 0%;
  transition: width .3s ease-in-out;
  /* Раскомментировал transition для плавного изменения ширины */
}

.heder_menu-object:hover:after,
.heder_menu-object:focus:after,
.heder_menu-object:active:after {
  /* Добавляем :active для анимации линии при нажатии */
  width: 100%;
  /* Линия растягивается до полной ширины при наведении, фокусе и активации */
}

.isActive {
  color: #022B94;
}

.isActive:after {
  width: 100%;
}

.full_news-content {
  display: flex;
  flex-direction: column;
  max-width: 1236px;
  margin: auto;
}

.full_news_img-bg {
  width: 694px;
  height: 394px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

.news_title_full {
  line-height: 1;
  margin-bottom: 30px;
  margin-top: 30px;
}

.slider-media {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 200px;
}

.show_all-news_btn {
  font-family: nunito;
  font-size: 20px;
  width: 173px;
  
  text-align: right;

  cursor: pointer;
}
.full_news_btns_return{
  margin-top: 0!important;
  margin-bottom: 0!important;
}

.show_all-news_btn:hover {
  color: #022B94;
}

.slider-img_full-news {
  width: 100%;
  max-width: 875px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 500px;

}
.swiper{
  z-index: 1;
}
/* Стили для кастомных стрелок */
.swiper-button-next-custom,
.swiper-button-prev-custom {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
}

.swiper-button-next-custom {
  right: 10px;
}

.swiper-button-prev-custom {
  left: 10px;
}


.news_text {
  padding-right: 36px;
  font-family: nunito;
  font-size: 20px;
}

.full_news_img-bg_small {
  width: 220px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
}

.small_media_container {
  padding-top: 17px;
}

.feedback_form-container {
  /* margin-left: 160px; */
  display: flex;
  flex-direction: column;
}

.feedback_form-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(79, 79, 79, 0.9);
  z-index: 101;
  opacity: 0;
  transform: translateY(-100%);

  pointer-events: none;
}

.feedback_form-modal.visible_form {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0);

  transition: opacity 0.3s ease, transform 0.3s ease;

}

.feedback_form-modal.hidden {
  opacity: 0;
  pointer-events: none;
  transform: translateY(-100%);
  transition: opacity 1.3s ease, transform 0.3s ease;
}

.feedback_form {
  position: absolute;
  top: 0;
  right: 0;
  /* transform: translate(-50%, -50%); */
  max-width: 963px;
  width: 100%;
  height: 100%;
  background-image: url("../public/feedbackform.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
  padding-bottom: 16.5rem;
}

.feedback_form-title {
  font-size: 20px;
  font-family: nunito;
  padding-bottom: 15px;
}

.feedback_form-textarea {
  width: 40.1875rem;
  height: 30.625rem;
  margin-bottom: 4.0625rem;
  background-color: #E3EBFF;
  border: 1px solid #959595;
  border-radius: 5px;
}
.feedback_form-textarea:focus {
  border: 2px solid #022B94!important;
}
.feedback_form-textarea:focus-visible{
  border: 2px solid #022B94!important;
  outline: none;
}

.feedback_success-message {
  max-width: 36.1875rem;
  width: 100%;
  margin: auto;
  font-size: 28px;
  font-family: nunito;
  text-align: center;
  margin-top: 45px;

}

.heder_menu-object_form-feedback {
  background-image: url("../public/feedback-new.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 36px;
  height: 36px;
  transition: 0.5s;

}

.heder_menu-object_form-feedback:hover {
  background-image: url("../public/feedbackhover-new.svg");
  transition: 0.5s;
}
.heder_menu-object_form-feedback.profile-icon {
  background-image: url("../public/user-icon.svg");
}

.heder_menu-object_form-feedback.profile-icon:hover {
  background-image: url("../public/user-icon-hover.svg");
}

.feedback_form-btn {
  font-size: 20px;
  font-family: nunito_medium;
  border: none;
  background-color: #022B94;
  color: #fff;
  border-radius: 5px;
  width: 40.1875rem;
  padding-bottom: 16px;
  padding-top: 16px;
}

.feedback_form-close {
  position: absolute;
  top: 40px;
  right: 160px;

  cursor: pointer;
  z-index: 1;
}

/* Общие стили */
body.modal-open {
  overflow: hidden;
  /* Отключает прокрутку при открытом модальном окне */
}

/* Стили модального окна */
.modal-slider_full-news {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(79, 79, 79, 0.9);
  /* Полупрозрачный черный фон */
  z-index: 1000;
  /* Убедитесь, что модальное окно поверх всех других элементов */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  height: 100%;
  /* Добавьте прокрутку, если контент превышает размер окна */
}

.hidden_arrow {
  display: none;
}
.close-btn-edit{
  width: 309px;
  height: 30px;
  position: absolute;
  left: 0px;
  top: -51px;
  background-color: #E3EBFF;
  display: flex;
  align-items: center;
}
.back_to_news_list{
  margin-right: 19px;
  display: flex;
  align-items: center;
}
.back_to_news_list-text{
  font-size: 18px;
  font-family: nunito;
  color: #022B94;
}

.close-btn-container {
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 2;
  width: 41px;
  height: 41px;
}
.not-found-page
  {
    min-height: 100vh;
    height: 100%;
    background-color: rgb(0, 36, 86);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.not-found-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  height: 100%;
  padding-right: 80px;
}

.not-found-text{
  max-width: 711px;
  width: 100%;
  font-size: 20px;
  font-family: nunito;
  text-align: center;
  margin-top: 81px;
  margin-bottom: 81px;
}
.not-found-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.not-found-button{
  font-size: 20px;
  font-family: nunito_semibold;
  border: none;
  background-color: #011A59;
  color: #fff;
  border-radius: 5px;
  width: 278px;
  padding-bottom: 18.5px;
  padding-top: 18.5px;
  cursor: pointer;
}

.optimized-main-container{
  background-image: url("../public/Mainb.svg");
  background-attachment: fixed;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}











/* gant style*/
/* .ganttlocale{
  position: relative;
  z-index: 99999;
  width: 268px;
} */
._WuQ0f {

  padding-left: 16px;
  padding-right: 16px;
  font-family: nunito_extrabold;
  font-size: 16px;
  background-color: #fff;
}

._9w8d5 {
  font-size: 13px;
  font-family: nunito_extrabold;
}

._2q1Kt {
  font-size: 16px;
  font-family: nunito_extrabold;
}

._nI1Xw {
  font-size: 16px;
}

._3ZbQT {
  width: 100%;
  border-bottom-left-radius: 5px;
}

._3lLk3 {
  white-space: wrap !important;
  padding-left: 16px;
  font-size: 16px;
  

}
._34SS0 {
  background-color: #fff;
}

._1nBOt {
  width: 800px;
}

._3_ygE {
  width: 600px;
  border-top-left-radius: 5px;
}

._2TfEi {
  margin-left: -16px;
}

._nI1Xw {
  line-height: 20px;
  font-weight: bold;
}

/* styles.css */

/* .fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
 
}

.fixed-news-slider {
  position: fixed;
  top: 130px;

  left: 0;
  width: 100%;
  z-index: 999;

}

.full_news_container {
  margin-top: 302px;

  overflow-y: auto;

  height: calc(100vh - 120px);
 
}

.modal-slider_full-news {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10000;
 
  display: flex;
  justify-content: center;
  align-items: center;
} */



.admin-page {
  display: flex;
  min-height: 100vh;
  position: static;
  height: auto;
}
.news-search {
  display: flex;
  flex-direction: row;
 
}

.news-search-container {
  display: flex;
  align-items: center;
 position: relative;
 margin-right: 70px;
}

.news-search-input {
  width: 473px;
  padding: 0.5rem;
  border: 0.5px solid #959595;
  border-radius: 4px;
  font-size: 14px;
  font-family: nunito;
}

.news-search-input:focus {
  outline: none;
  border-color: #022B94;
}

.news-search-button {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.news-search-button img {
  width: 20px;
  height: 20px;
  margin-bottom: 5px;

}

.sidebar {
  width: 352px;
  background-color: #022B94;
  color: white;
  padding: 20px;
  position: relative;
  z-index: 1;
  padding-left: 50px;

}

.sidebar h2 {
  color: #f0f0f0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {

  cursor: pointer;

  position: relative;
}

.sidebar li.active {
  /* background-color: #2c5282; */
  position: relative;
}

.sidebar li.active::before {
  content: '';
  position: absolute;
  right: -32px;
  /* Подкорректируйте значение в зависимости от размеров вашей иконки */
  top: 50%;
  transform: translateY(-50%);
  width: 108px;
  /* Подкорректируйте значение в зависимости от размеров вашей иконки */
  height: 65px;
  /* Подкорректируйте значение в зависимости от размеров вашей иконки */
  background: url('./icons/toggle2.svg') no-repeat center center;
  background-size: contain;
}

.sidebar li:hover {
  /* background-color: #2c5282; */
}

.content {
  flex: 1;
  padding-left: 70px;
  background-color: #E3EBFF;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  position: relative;
  z-index: 2;
  margin-left: -30px;
  padding-bottom: 40px;
}
.content div{
 
}

.add-news {
  position: relative;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 750px;
  width: 100%;
}
.add_news_title{
  font-size: 20px;
  font-family: nunito;
  margin-bottom: 20px;
  margin-top: 40px;
}
.add-news-toggle-button{
  background: none;
  /* width: 253px; */
  border: none;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  font-size: 18px;
  font-family:nunito_semibold ;
  margin-top: 30px;
  margin-bottom: 20px;
}
.return-news-icon{
  margin-right: 16px;
}
.add_news-icon{
  margin-right: 16px;
}
.add-news-toggle-button:hover {
  color:#022B94 ;
}
.add-news-toggle-button:hover img.add_news-icon {
  filter: brightness(0) saturate(100%) invert(20%) sepia(95%) saturate(2800%) hue-rotate(206deg) brightness(75%) contrast(105%);
}



.form-group {
  margin-bottom: 15px;
}

.label_add_news {
  display: block;
  margin-bottom: 10px;

}

.file-input-wrapper {
  position: relative;
  display: inline-block;
}

.file-input-wrapper input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.custom-file-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  background-color: #fff;
  color: #959595;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-family: nunito;
  align-items: baseline;

}

.custom-file-button img {
  margin-right: 10px;
}

.custom-file-button:hover {
  /* background-color: #2b6cb0; */

}

.file-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.file-preview {
position: relative;
  border-radius: 5px;
  /* overflow: hidden; */
}
.remove-file-button{
  width: 14px;
  height: 16px;
}
.close-btn_img{
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="date"],
.form-group input[type="datetime-local"],
.form-group textarea {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  box-sizing: border-box;
  border: 1px solid #cbd5e0;
  border-radius: 5px;
}

.form-group input[type="text"]:focus,
.form-group input[type="date"]:focus,
.form-group input[type="datetime-local"]:focus,
.form-group textarea:focus {
  border: 1px solid #022B94;
  outline: none;
}

/* AddNews.css */
.success-message {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px;
  left: 51%;
  transform: translateX(-50%);
  background-color: #20AC47;
  /* Зелёный фон */
  color: white;
  /* Белый текст */
  padding: 10px 20px;
  border-radius: 5px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */

  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
  max-width: 69.4375rem;
  width: 100%;
  font-family: nunito;
  font-size: 20px;
}

.success-icon {

  margin-right: 10px;
}


.form-group input[type="file"] {
  display: block;
  margin-top: 10px;
}

.file-preview-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.file-preview img {
  border-radius: 4px;
}

.save-button {
  background-image: linear-gradient(to right, rgb(2 43 148), rgb(57 144 254));
  border: 0;
  color: #fff;
  font-size: 18px;
  font-family: nunito_semibold;
  padding-left: 57.5px;
  padding-right: 57.5px;
  padding-top: 11px;
  padding-bottom: 11px;
  border-radius: 5px;
}

.save-button-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.save-button:hover {
  /* background-color: #2563eb; */
}

.add-news_title {
  font-size: 18px;
  font-family: nunito_semibold;
  margin-bottom: 30px;
}

.label_add_news {
  font-size: 16px;
  font-family: nunito;
}

.add_news_text {
  height: 192px;
  font-size: 16px;
  font-family: nunito;
}

.add_news_textarea {
  font-size: 16px;
  font-family: nunito;
}

.add_news_checkbox {
  width: 23px;
  height: 23px;
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.add_news_checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.add_news_checkbox+label::before {
  content: '';
  display: inline-block;
  width: 23px;
  height: 23px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #181818;
  border-radius: 0.45em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.add_news_checkbox:checked+label::before {
  border-color: #181818;
  background-color: #388FFD;
  background-image: url(./icons/check.svg);
}

.sidebar-title {
  font-size: 22px;
  font-family: nunito_medium;
  margin-left: 34px;
}

.sidebar_heder_icon {
  position: relative;
}

.blure_circle {
  width: 70px;
  height: 70px;
  /* background-image: linear-gradient(to right, rgb(255 255 255 /0%), rgb(255 255 255 /100%)); */
  background: rgba(62, 160, 254, 0.35);
  /* Полупрозрачный фон */
  backdrop-filter: blur(24px);
  border-radius: 50%;
  position: relative;
  z-index: 3;
}

.small_circle {
  width: 55px;
  height: 55px;
  background: #4096FE;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(30%, -50%);
  z-index: 1;
}

.small_circle-inside {
  width: 26px;
  height: 26px;
  background: rgba(0, 98, 255, 1);
  filter: blur(18px);
  /* Изменено с backdrop-filter на filter */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  opacity: 0.5;
}

.union_circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-30%, -40%);
  z-index: 4;

}

.sidebar_header {
  display: flex;
  align-items: center;

}

.sidebar-menu li {
  font-size: 18px;
  font-family: nunito_medium;
  margin-bottom: 60px;
}

.sidebar-menu-content {
  display: flex;
}

.sidebar-menu-icon {
  margin-right: 15px;
}

.sidebar_title_menu {
  font-size: 16px;
  font-family: nunito_medium;
  margin-top: 40px;
  margin-bottom: 40px;
}
.users-list {
  padding: 20px;
}

.users-list h2 {
  text-align: left;
  font-size: 24px;
  margin-bottom: 20px;
}
.users-container{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

}

.users-table {
  
  width: 1385px; /* Фиксированная ширина таблицы */
  overflow-x: auto;
  overflow-y: hidden;
  /* max-height: 80vh; Ограничиваем высоту таблицы */
  border-collapse: collapse;
}
.add-contract-input{
  width: 389px;
  margin-right: 23px;
}
.users-table thead {
  background-color: #003087;
  color: #fff;
}

.users-table th,
.users-table td {
  padding: 10px;
  text-align: left;

}
.table-header-container{
  background-color: #022B94;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.table-header{
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* padding-top: 22px;
  padding-bottom: 22px; */
  
  align-items: center;
  height: 70px;
  padding-left: 10px;
}
.table-header, .table-body {
  /* display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px; */
}

.users_position-button{
  position: relative;
}
.add-user-button{
  background-color: #E3EBFF;
  background-image: url("/public/add-square.svg");
  background-repeat: no-repeat;
  color: #181818;
  border: none;
padding-left: 36px;
  cursor: pointer;
  align-self: flex-start!important;
  width: 260px;
  font-size: 18px;
  font-family: nunito_semibold;
  align-self: end;
  margin-top: 10px;
  margin-bottom: 30px;
  background-size: contain;
}
.delete-contract-btn{
  background-color: #fff;
  width: 18px;
  height: 18px;
  background-image: url("/public/trash-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: none;
  position: absolute;
  right: 36px;
  top: 14px;
}
.add-contract-label-position-button{
  position: relative;
}
.add-user-button:hover{
  color: #022B94;
  background-image: url("/public/add-square-hover.svg");
}
.table-header_item{
  color: #FAFAFA;
  font-size: 18px;
  font-family: nunito_medium;
  max-width: 168px;
  width: 100%;
}
.region_margin{
  margin-right: 10px;
}
.table-body-container{
  background-color: #FAFAFA;
  padding-bottom: 10xp;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 751px;/*При необходимости вернуть 80vh или 100vh*/
}
.users-table_container-position{
  transition: width 0.3s ease;
  overflow: hidden; /* Скрываем содержимое, если оно выходит за границы */
  max-width: 100%; /* Ограничиваем максимальную ширину контейнера */
}
.users-table_container.compressed{
  width: 700px;
  overflow-y: auto;
  overflow-x: auto;
  margin-right: 70px;
}
.table-body{
  border-bottom: 1px solid #959595;
  padding-top: 5px;
  padding-bottom: 5px;
}
.table-body{
  display: flex;
  flex-direction: row;
  
  padding-left: 10px;
  align-items: center;
}
.table-body_item{
  max-width: 179px;
  width: 100%;
  font-size: 16px;
  font-family: nunito;
  /* margin-right: -10px; */
  /* white-space: nowrap; Запрещаем перенос текста */
    overflow: hidden;
    /* text-overflow: ellipsis; Элипсис для длинного текста */
    /* word-wrap: break-word; */
}
.user-form-container{
  flex: 1; /* Форма занимает оставшееся пространство */
    max-width: 400px; /* Ограничиваем максимальную ширину формы */
    margin-top: -111px;
}
.users-table.open-form .users-table_container-position {
  width: 700px;
}
.users-table th {
  font-weight: bold;
}

.users-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.users-table tbody tr:hover {
  background-color: #e6f7ff;
}
.actions{
  display: flex;
  flex-direction: row;
  justify-content: center;
 
}
.user_email{
  width: 101%;
}

.edit-button,
.delete-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 18px;
  font-family: nunito_semibold;
}

.edit-button img,
.delete-button img {
  width: 24px;
  height: 24px;
}
.add-user-form {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 636px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
}

.add-user-form label {
  display: flex;
  flex-direction: column;
 font-size: 16px;
 font-family: nunito_medium;
  color: #181818;
  margin-bottom: 4px;
}

.add-user-form input,
.add-user-form select,
.selected-value {
  padding: 10px;
  /* margin-top: 5px; */
  border: 1px solid #959595;
  border-radius: 4px;
  font-size: 16px;
  font-family: nunito_medium;
}

.add-user-form input:focus,
.add-user-form select:focus {
  outline: none;
  border-color: #022B94;
}
.password-container {
  display: flex;
  align-items: center;

}
.edit-icon{
  margin-right: 10px;
}
.edit-icon:hover{
  filter: brightness(0) saturate(100%) invert(20%) sepia(95%) saturate(2800%) hue-rotate(206deg) brightness(75%) contrast(105%);
}
.delete-icon_users:hover{
  filter:invert(58%) sepia(20%) saturate(7158%) hue-rotate(323deg) brightness(103%) contrast(111%);
  


}
.password-container .pass-gen {
 
  background-color: #FFF;
  color: #181818;
  border: 2px solid transparent; /* Set border to 2px */
  border-radius: 4px;
  cursor: pointer;
  background-image: linear-gradient(#FFF, #FFF), /* For button background */
                    linear-gradient(90deg, #022B94, #3990FE); /* Gradient for border */
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.pass-gen_txt{
  margin-right: 12px;
  margin-left: 12px;
  margin-top: 9px;
  margin-bottom:9px;
  font-size: 16px;
  font-family: nunito_semibold;
}


.add-contract-btn
 {
 padding-left: 36px;
  background-color: #fff;
  background-image: url("/public/add-square.svg");
  background-repeat: no-repeat;
  color: #181818;
  border: none;

  cursor: pointer;
  align-self: flex-start!important;
  width: 187px;
  font-size: 16px;
  align-self: end;
  margin-top: 34px;
}
.save-btn {
  background: linear-gradient(90deg, #022B94 0%, #3990FE 100%);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  font-family: 'nunito_medium';
  cursor: not-allowed;
  border-radius: 5px;
  transition: background 0.3s ease;
  width: 208px;
  margin-top: 55px;
}
.form-title{
  font-size: 18px;
  font-family: nunito_semibold;
  margin-bottom: 15px;
}
.search-container {
  margin-bottom: 20px;

  justify-content: center;
}
.users-title{
  font-size: 20px;
  font-family: nunito;
  margin-top: 70px;
  margin-bottom: 20px;

}

.search-container input {
  width: 616px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #959595;
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  background-image: url("/public/search-icon1.svg");
  background-repeat: no-repeat;
  background-position: 585px;
  
}
.search-container input:focus {
  outline: none;
  border-color: #022B94;
}

.save-btn:disabled {
  background: linear-gradient(90deg, #022B94 0%, #3990FE 100%);
  opacity: 0.6;
  cursor: not-allowed;
}

.save-btn:not(:disabled) {
  background: linear-gradient(90deg, #3990FE 0%, #022B94 100%);
  cursor: pointer;
}

.save-btn:hover:not(:disabled) {
  background: linear-gradient(90deg, #3990FE 0%, #022B94 100%);
}

.add-contract-btn:hover,

.password-container button:hover {

 
}
.add_contracts{
  display: flex;
  flex-direction: row;
justify-content: space-between;
  align-items: center;
font-size: 16px;
font-family: nunito;
}
/*Styles News LIst*/
.news-list {
  display: flex;
  flex-direction: column;
 max-width: 868px;
  margin-top: 20px;
}

.news-item {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
 padding-top: 10px;
 padding-bottom: 10px;
 

}

.news-cover {
  width: 196px;
  height: 140px;
  object-fit: cover;

  margin-right: 10px;
}

.news-details {
display: flex;
flex-direction: column;
justify-content: space-between;
}

.news-title {
  font-size: 18px;
  font-family: nunito_semibold;
  
}

.news-date {
  font-size: 16px;
font-family: nunito;

  margin-bottom: 10px;
}

.news-text {
  font-size: 1rem;
  color: #333;
}


/* Modal delete window styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 1000; /* Поверх всех элементов */
}

/* Стили модального окна */
.modal_windos-delete {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 474px;
  width: 100%;

  text-align: center;
}

.modal_windos-delete h2 {
  margin-bottom: 20px;
  font-size: 18px;
}

.modal-body {
 
}
.delete_news-title{
  margin-top: 26px;
 margin-bottom: 26px;
  font-size: 18px;
  font-family: nunito_semibold;
  word-break: break-all;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: 0;
}

.delete-button {
  background: linear-gradient(to right, #D83636 0%, #FC6868 100%);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 140px;
  height: 45px;
  font-family:nunito_semibold ;
  font-size: 18px;
}

.edit-button_news-list{
  width: 44px;
  height: 44px;
  background-color:#FAFAFA ;
  border: none;
  border-radius: 5px;
  position: absolute;
  top: 0;
  right: -26px;
  transform: translate(100%);
}
.delete-button_news-list{
  right: -96px;
}
.delete-button_news-list img:hover{
  filter: invert(58%) sepia(20%) saturate(7158%) hue-rotate(323deg) brightness(103%) contrast(111%);
}
.edit-button_news-list img{
  margin-right: 0;
  margin-bottom: 3px;
}
.cancel-button {
  background-color: #fff;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  border: 1px solid #959595;
  cursor: pointer;
  font-family:nunito_semibold ;
  font-size: 18px;
  width: 140px;
  height: 45px;
}
.modal-header_delete{
  font-size: 30px;
  font-family:nunito_medium ;
  line-height: 1.3;
}
.delete_user-input{
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 16px;
  font-family: nunito;
  border-radius: 5px;
  border: 1px solid #959595;
  outline: none;
  margin-bottom: 37px;
}
.delete_user-input :focus {
  outline: none;
  border-color: #022B94;
}
.modal-body_delete-login{
  font-size: 16px;
  font-family: nunito;
text-align: left;
margin-bottom: 5px;
margin-top: 37px;
}



/*Snack Bar style*/
.snackbar {
  position: fixed;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);
  /* background-color: #38B15A; Зеленый фон */
  color: white;
  padding: 9px;
  border-radius: 5px;
  font-size: 20px;
  font-family: nunito;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  max-width: 1368px;
  width: 100%;
  margin-left: 46px;
}

.snackbar_users{
  position: fixed;
  bottom: 70px;
  left: 56%;
  transform: translateX(-50%);
  /* background-color: #38B15A; Зеленый фон */
  color: white;
  padding: 9px;
  border-radius: 5px;
  font-size: 20px;
  font-family: nunito;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  max-width: 1385px;
  width: 100%;
  margin-left: 46px;
}
.documents_container{
  position: relative;
  /* padding-bottom: 80px; */
}
.snackbar-container_width{
  position: relative;
  max-width: 1111px;
}
.snackbar_documents{
  position: absolute;
  bottom: 20px;
  left: 0;
  /* transform: translateX(-50%); */
  /* transform: translateY(-30%); */
  /* background-color: #38B15A; Зеленый фон */
  color: white;
  padding: 9px;
  border-radius: 5px;
  font-size: 20px;
  font-family: nunito;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* max-width: 1430px; */
  width: 100%;
  margin-left:auto; 
}
.snackbar_edit_news{
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  /* background-color: #38B15A; Зеленый фон */
  color: white;
  padding: 9px;
  border-radius: 5px;
  font-size: 20px;
  font-family: nunito;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  max-width: 1111px;
  width: 100%;
  margin-left:auto; 
}
.snackbar-content {
  display: flex;
  align-items: center;
}

.snackbar-message {
  margin-left: 10px;
}

/*form select option styles*/

.custom-select {
  position: relative;
 
}
.selected-value{
  height: 42px;
  color: #181818 !important;
}

.custom-select .selected-value {
  /* color: #959595; Цвет для placeholder */
}

.custom-select .selected-value.filled {
  color: #000000; /* Черный цвет для выбранного значения */
}

.custom-select select {
  display: none; /* Прячем стандартный select */
}

.custom-select::after {
  content: '';
  position: absolute;
  right: 4px;
  top: 14px;
  width: 21px; /* Устанавливаем ширину */
  height: 11px; /* Устанавливаем высоту */
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02LjUgOC41QzYuMjExMjggOC41IDUuOTIyNTYgOC4zODg2MSA1LjY5NDYyIDguMTQ5OTNMMC4zMzA1MDkgMi41MzI4MkMtMC4xMTAxNjkgMi4wNzEzNiAtMC4xMTAxNjkgMS4zMDc1NiAwLjMzMDUwOSAwLjg0NjA5NkMwLjc3MTE4NiAwLjM4NDYzNCAxLjUwMDU4IDAuMzg0NjM0IDEuOTQxMjYgMC44NDYwOTZMNi41IDUuNjE5ODRMMTEuMDU4NyAwLjg0NjA5NkMxMS40OTk0IDAuMzg0NjM0IDEyLjIyODggMC4zODQ2MzQgMTIuNjY5NSAwLjg0NjA5NkMxMy4xMTAyIDEuMzA3NTYgMTMuMTEwMiAyLjA3MTM2IDEyLjY2OTUgMi41MzI4Mkw3LjMwNTM4IDguMTQ5OTNDNy4wNzc0NCA4LjM4ODYxIDYuNzg4NzIgOC41IDYuNSA4LjVaIiBmaWxsPSIjMDIyQjk0Ii8+Cjwvc3ZnPgo=');
  background-size: contain; /* Масштабируем изображение пропорционально контейнеру */
  background-repeat: no-repeat; /* Убираем повтор */
  pointer-events: none; /* Отключаем взаимодействие */
}
.return-login-button:hover img.return-icon-login-page{
  filter: brightness(0) saturate(100%) invert(20%) sepia(95%) saturate(2800%) hue-rotate(206deg) brightness(75%) contrast(105%);
}

.custom-select.open::after {
  content: '';
  position: absolute;
  right: 4px;
  top: 14px;
  width: 21px; /* Устанавливаем ширину */
  height: 11px; /* Устанавливаем высоту */
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEzIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik02LjUgMC40OTk5OTlDNi43ODg3MiAwLjQ5OTk5OSA3LjA3NzQ0IDAuNjExMzg3IDcuMzA1MzggMC44NTAwNzRMMTIuNjY5NSA2LjQ2NzE4QzEzLjExMDIgNi45Mjg2NCAxMy4xMTAyIDcuNjkyNDQgMTIuNjY5NSA4LjE1MzlDMTIuMjI4OCA4LjYxNTM3IDExLjQ5OTQgOC42MTUzNyAxMS4wNTg3IDguMTUzOUw2LjUgMy4zODAxNkwxLjk0MTI2IDguMTUzOUMxLjUwMDU4IDguNjE1MzYgMC43NzExODYgOC42MTUzNiAwLjMzMDUwOCA4LjE1MzlDLTAuMTEwMTY5IDcuNjkyNDQgLTAuMTEwMTY5IDYuOTI4NjQgMC4zMzA1MDggNi40NjcxOEw1LjY5NDYyIDAuODUwMDc0QzUuOTIyNTYgMC42MTEzODcgNi4yMTEyOCAwLjQ5OTk5OSA2LjUgMC40OTk5OTlaIiBmaWxsPSIjMDIyQjk0Ii8+Cjwvc3ZnPgo=');  /* Стрелка вверх при открытом списке */
  background-size: contain; /* Масштабируем изображение пропорционально контейнеру */
  background-repeat: no-repeat; /* Убираем повтор */
  pointer-events: none; /* Отключаем взаимодействие */


}

.custom-select.open .custom-select-options {
  display: block;
}

.full_news_btns{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 17px;
  margin-bottom: 50px;
}
.full_news_btns_return {
  font-size: 20px;
  font-family: nunito;
}


.custom-select-options {
  display: none;
  position: relative;
  top: 0px;
  left: 0;
  width: 100%;
  background-color: white;
  border: 0.5px solid #959595;
  border-bottom: 0px;
  border-radius: 5px;
  /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); */
  z-index: 100;
}

.custom-select-options .custom-option {
  padding: 10px;
  cursor: pointer;
  border-bottom: 0.5px solid #959595;

}

.custom-select-options .custom-option:hover {
  background-color: #E3EBFF;
}

/*Registration form style*/
.registration_form{
  width: 1236px;
  margin: auto;
  margin-top: 70px;
}

.registration_page{
  min-height: 100vh;
  height: 100%;
}
.registration_form-header{
  background-color: #FAFAFA;
  border-radius: 15px;
  padding-left: 37px;
  padding-right: 37px;
  padding-top: 10px;
  padding-bottom: 20px;
  margin-bottom: 25px;
}

.form_button_submit_reg-toback{
  background: none;
  outline: none;
  color:#022B94;
  font-size: 18px;
  font-family: nunito;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1.5px solid #022B94;
  padding: 0;
}

.selected-value-regform{
  color: #959595!important;
}

.custom-option_reg{
  font-size: 26px;
  font-family: nunito_medium;
  padding-left: 35px!important;
  padding-top: 7.5px!important;
  padding-bottom: 7.5px!important;
}

.form_button_submit_reg{
  background-color: #022B94;
  color: #F3F3F3;
  font-size: 26px;
  font-family: nunito_semibold;
  padding-left: 57.5px;
  padding-right: 57.5px;
  padding-top: 14.5px;
  padding-bottom: 14.5px;
}
.registration_form_title{
  font-size: 45px;
  font-family: nunito_semibold;
  color: #022B94;
  border-bottom: 1px solid #959595;
}

.registration_form_info{
  font-size: 26px;
  font-family: nunito;
  margin-top: 20px;
}
.registration_form_group{
  background-color: #FAFAFA;
  border-radius: 15px;
  padding-left: 37px;
  padding-right: 37px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 25px;
}

.registration_form_group-title{
  font-size:26px;
  font-family: nunito_medium;
  margin-bottom: 30px;
  line-height: 1;
}

.registration_form_group-input{
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #959595;
  border-radius: 0;
  width: 100%;
  background-color:#FAFAFA;
  outline: none;
  font-family: nunito;
  font-size: 26px;

}

.registration_form_group-input:focus{
  border-bottom: 2px solid #022B94;
}

.registration_form_group-input::placeholder{
  color: #959595;
}


/*Project Page Styles*/
.main_project_container{
  max-width: 1236px;
  width: 100%;
  margin: auto;
}
.main_project_col{
  display: flex;
  flex-direction: column;
}
.custom-select-value{
  width: 257px;
  outline: none;
  border: 1px solid #022B94;
  border-radius: 5px;
  background-color: #FAFAFA;
  margin-left: auto;
  background-image: url("../public/elementequal1.svg");
  background-repeat: no-repeat;
  background-position: 10px;
  padding-left: 56px;
  height: 44px;

color: #022B94;
font-size: 16px;
font-family: nunito_semibold;
 

}
.close-btn_user-form{
  position: absolute;
  right: 10px;
  top: 5px;
}
.no-stages-found{
  margin-top: 67px;
  font-size: 26px;
  font-family: nunito_semibold;
}
.custom-select-project{
  top: 44px;
  width: 257px;
  margin-left: auto;
}
.custom-select-project::after{
  top: 14px!important;
}
.custom-select-value.open-radius {
  /* border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; */
}

.table-header_item-none-project{
  border-top-left-radius: 5px;
 
}

.custom-select-options-project{
  width: 257px;
  margin-left: auto;
  /* margin-right: -4px; */
  border: 0.5px solid #022B94;
  /* border-top-left-radius: 0px;
  border-top-right-radius: 0px; */
  position: absolute;
  top: 42px;
  right: 0;
  border-bottom: none;
}
.no-adjustments{
  background-color: #DEE7FF;
  font-size: 15px;
  font-family: nunito;
  padding-top: 21.5px;
  padding-bottom: 21px;
  padding-left: 10px;
}



.custom-select-options-project .custom-option{
  border-bottom: 0.5px solid #022B94;
  background-image: url("../public/documenttext1.svg");
  background-repeat: no-repeat;
  background-position: 16px;
  padding-left: 56px;
line-height: 1;
color: #022B94;
font-size: 16px;
font-family: nunito_semibold;
}
.table-project{
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.table-header_project{
  background-color: #022B94;

  /* padding-top: 19px;
  padding-bottom: 19px;
  padding-right: 19px;
  padding-left: 50px; */
}
.table-header_project tr{
 
}
.table-header_item-none{
  width: 50px;
  border-top-left-radius: 5px;
}

.table-header_project th{
  color: #F3F3F3;
  font-size: 16px;
  font-family: nunito_semibold;
  padding-top: 19px;
  padding-bottom: 19px;
}
.table-header_item-tr{
  background-color: #FAFAFA;
  border-bottom: 1px solid #959595;
  font-size: 15px;
}
.table-header_item-index{
  font-family: nunito;
  width: 0px;
}
.table-header_item-name{
  font-family: nunito_bold;
  padding-left: 39px;
  width: 314px;
}
.table-header_item-date-start{
  width:13%;
  padding-right: 60px;
}
.table-body_adjustments tr{
  font-size: 15px;
  border-bottom: 1px solid #6E97FF;
}
.toggle-icon {
  margin-left: 6px;
  transition: transform 0.3s ease; /* Плавная анимация */
}
/* Статичный стиль для кнопок */
.button-style {
  color: #959595; /* Цвет текста, если нет класса active */
  padding: 10px;
  border: 0.5px solid #022B94;
  background-color: #FAFAFA;
  cursor: pointer;
  font-size: 16px;
  font-family: nunito_semibold;
  padding-top: 9px;
  padding-bottom: 9px;

}
.button-style-container{
  margin-bottom: 24px;
  height: 44px;
  position: relative;
  z-index: 100;
  width: 18%;
}
.button-style-day{
  border-right: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.button-style-month{
  border-left: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* Стиль для активной кнопки */
.button-style.active {
  color: #022B94; /* Цвет текста, если есть класс active */
  font-family: nunito_bold;
}


.rotate-180 {
  transform: rotate(180deg); /* Поворот на 180 градусов */
}
.table-body_adjustments td{
  
 /* border-bottom: 1px solid #959595; */
}
.table-header_item-date-end{
  width: 137px;
}
.table-header_item-date{
  font-family: nunito;
}
.table-header_item-date-fact{
  width: 14%;
  padding-right: 20px;
  border-top-right-radius: 5px;
}
.table-header_item-status{
  font-family: nunito_semibold;
  width: 94px;
}
.table-header_item-tr td{
  /* padding-top: 19px;
  padding-bottom: 19px; */

}
.document-not-found{
  font-size: 15px;
  font-family: nunito_semibold;
}
.soglas-active{
  /* background-color: #D1DEFF; */
}
.table-header_item-date-th{
  width: 169px;
  padding-right: 19px;
  border-top-right-radius: 5px;
}
.table-header_item-status-th{
  width: 133px;
}
.project-stages-table_title{
  margin-top: 30px;
  margin-bottom: 30px;

  font-size: 26px;
  font-family: nunito_semibold;
  line-height: 1;
}
.registration_form_group-input_hiegth{
  height: 49px;
  padding-left: 0px;
}
.table-adjustments{
  width: 100%;
  background-color: #DEE7FF;
  padding-left: 10px;
  padding-top: 16px;
}
.table-header_adjustments-name{
  font-family: nunito;
  font-size: 15;
  /* width: 39%; */
  padding-left: 10px;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
}
.overdue-task ._31ERP {
  fill: #FF0000 !important;
}
.table-header_adjustments-date{
  width: 22%;
}
.table-header_adjustments-status{
  width: 93px;
  word-spacing: 3000px;
  font-family: nunito_semibold;
  font-size: 15px;
}
.table-header_adjustments th{
  padding-top: 16px;
  font-size: 16px;
  color: #022B94;
  font-family: nunito_semibold;
}
.download-button{
  background: none;
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 0.5px solid #022B94;
  border-radius: 0;
  padding: 0;
  line-height: 1;
  color: #022B94;
  font-size: 15px;
  font-family: nunito_semibold;
  margin-left: 20px;
  height: 14px;
}
   .documents-title{
    font-size: 26px;
    font-family: nunito_semibold;
    color: #181818;
    margin-bottom: 14px;
    margin-top: 30px;
   }
.document-name{
  font-size: 15px;
  font-family: nunito_semibold;
  color: #181818;

}
.document-list{
  width: max-content;
}
.document-row{
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  margin-bottom: 15px;
  align-items: center;
}

/*Document table*/
.documents {
  padding: 20px;
}
.documents-table-container {
  max-height: 100vh;
  overflow-y: auto;
}
.documents-table {
  width: 100%;
  border-collapse: collapse;
  /* margin-top: 20px; */
 
    
}

.documents-table th {
  background-color: #022B94;
  color: #FAFAFA;
  padding: 10px;
  font-size: 18px;
  font-family: nunito_medium;
}

.documents-table td {
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 16px;
  font-family: nunito;
}
.document-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}
.documents-table thead th {
  position: sticky;
  top: 0;
  background-color: #022B94;
  color: #FAFAFA;
  padding: 10px;
  font-size: 18px;
  font-family: nunito_medium;
  z-index: 1;
}
.documents_table-title{
  font-size: 20px;
  font-family: nunito;
  margin-bottom: 20px;
}

.contract-number{
  border-right: none!important;
  border-left: none!important;
}
.document-name{
  border-left: none!important;
  border-right: none!important;
}
.upload-button-container{
  text-align: center;
  border-right: none!important;
  border-left: none!important;
}
.upload-button{
  background: none;
  border-right: none;
  border-left: none;
  border-top: none;
 border-bottom: none;
  border-radius: 0;
  padding: 0;
  line-height: 1;
  color: #022B94;
  font-size: 14px;
  font-family: nunito;
}
.contract_header-number{
  width: 11%;
  border-top-left-radius: 5px;

}
.document_header-upload{
  border-top-right-radius: 5px;
}
.documents_table-body{
  overflow-x: auto;
  overflow-y: auto;
  max-height: 751px;
  background-color: #FAFAFA;
}
.upload-button-container_background{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.upload-icon{
  width: 16px;
  height: 16px;
  margin-right: 8px;
  display: flex;
}

.delete-button_documents {
  color: #D83636;
  font-size: 15px;
  font-family: nunito_semibold;
  background: none;
  border-right: none;
  border-left: none;
  border-top: none;
  border-bottom: 0.5px solid #D83636;
  border-radius: 0;
  padding: 0;
  line-height: 0;
  cursor: pointer;
  width: 60px;
  height: 15px;
  margin-left: 20px;
}
.cancel-button_document{
  border-bottom: none;
}
.delete_document-title{
  font-size: 15px;
  font-family: nunito_semibold;
  color: #181818;
  margin-bottom: 14px;
  margin-top: 14px;
  word-break: break-all;
}
.delete_document-contract{
  font-size: 15px;
  font-family: nunito_semibold;
  color: #181818;
  margin-bottom: 14px;
}
.download-button {
  color: #022B94;
  background: none;
  /* border: none; */
  cursor: pointer;
}
.row-margin{
  margin-bottom: 20px;
}
.KnowledgeBase_page{
  min-height: 100vh;
  height: 100%;

  background-color: rgb(0, 36, 86);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.KnowledgeBase_container{
  max-width: 1236px;
  width: 100%;
  margin: auto;
}
.KnowledgeBase_item{
  max-width: 354px;
  width: 100%;
  height: 191px;
background-color: #022B94;
border-radius: 10px;
}
.KnowledgeBase_title-main{
  font-size: 26px;
  font-family: nunito_semibold;
  color: #000;
  margin-bottom: 30px;
  margin-top: 60px;
}
.KnowledgeBase_item-logo{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 39px;
}
.form-group_flex{
  display: flex;
}
.form-group_flex .edit_news_checkbox{
  margin-right: 11px;
}
.form-group_flex .label_edit_news{
  font-size: 16px;
  font-family: nunito;
  margin-bottom: 0;
}
.KnowledgeBase{
  max-width: 1236px;
  width: 100%;
  margin: auto;
  background-color: #fff;
  padding: 12px;
  border-radius: 5px;
  margin-top: 60px;
}
.KnowledgeBase_title{
  font-size: 18px;
  font-family: nunito_bold;
  color: #022B94;
}
.KnowledgeBase_line{
  font-size: 18px;
  font-family: nunito;
  color: #022B94;
  margin-top: 11px;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* чтобы видео заполнило контейнер */
  pointer-events: none; /* отключает интерактивность видео, если нужно */
}
.large_media {
  position: relative;
  width: 694px;
  height: 394px;
  overflow: hidden;
}
.small_media {
  position: relative;
  width: 220px;
  height: 150px;
  overflow: hidden;
}
.video-play-trangle{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.profile-menu-container {
  position: relative;
  display: flex;
  align-items: center;
}

.profile-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.profile-icon {
  width: 36px;
  height: 36px;
}

.profile-dropdown {
  position: absolute;
  top: 55px;
  right: 0;
  background: #FAFAFA;
  border: 1px solid #FAFAFA;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0px;
  width: 216px;
  z-index: 100;
}
.dropdown-toggle{
  position: absolute;
  width: 16px;
  height: 13px;
  top: -10px;
  right: 13px;
}

.profile-username {
  margin: 0;
 padding-left: 14px;
  font-size: 16px;
  color: #022B94;
  font-family: nunito_semibold;
  align-content: center;
}

.logout-button {
  width: 100%;
  padding: 0;
  background-color: #FAFAFA;
  border: none;
  color: #E74242;
  cursor: pointer;
  border-radius: 0px;
  margin-top: 11px;
  font-size: 16px;
  font-family: nunito;
  border-top: 0.5px solid #E3EBFF;
  padding-top: 11px;
  padding-bottom: 11px;
}
.user-icon{
  width: 24px;
  height: 24px;
}
.profile-info{
  display: flex;
  flex-direction: row;
  padding-left: 14px;
  padding-top: 11px;
}

.base_content_container{
  max-width: 1236px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
}
.menu_title{
  font-family: nunito_semibold;
  font-size: 18px;
  color: #F3F3F3;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 17px;
  background-color: #022B94;
  border-radius: 5px;
}
.base_menu_item{
  display: flex;
  flex-direction: row;
  padding: 12px;
  align-items: center;
  gap: 16px;
  background-color: #F3F3F3;
  border-radius: 5px;
  margin-top: 8px;
}
.base_menu_item.active{
  background-color: #BACDFF;
  color: #F3F3F3;
}
.base_menu_name{
  font-size: 18px;
  font-family: nunito_bold;
  color: #022B94;
  width: 159px;
}
.menu_img_container{
  width: 42px;
  height: 42px;
}
.sections_panel{
  margin-top: 56px;
  margin-left: 20px;
}
.base_section_item{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 12px;
  background-color: #F3F3F3;
  border-radius: 5px;
  
}
.base_section_item.active{
  background-color: #BACDFF;
  color: #F3F3F3;
}
.sections_panel-container{
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 295px;
}
.base_section_name{
  font-size: 18px;
  font-family: nunito_semibold;
  color: #022B94;
}
.base_section_img{
  width: 22px;
  height: 22px;
}
.sections_articles_panel{
  display: flex;
  flex-direction: row;
  gap: 20px;

}
.articles_panel{
  margin-top: 56px;

}
.articles_panel-container{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.article_item {
  font-size: 18px;
  font-family: nunito_semibold;
  color: #022B94;
  background-color: #F3F3F3;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  width: 261px;

}
.article_item.active{
  background-color: #BACDFF;

}
.article_detail{
  position: relative;
  margin-top: 76px;
  width: 1236px;
    left: -592px;
    background-color: #E3EBFF;
    padding-top: 20px;
    padding-left: 20px;
}
.breadcrumbs{
  font-size: 13px;
  font-family: nunito_medium;
  color: #959595;
}
.breadcrumbs_container{
  max-width: 1236px;
  width: 100%;
  margin: auto;
padding-top: 30px;
padding-bottom: 30px;

}
.wiki-container {
  display: flex;
  gap: 30px;
  align-items: flex-start;
  position: relative;
}

.wiki-list {
  flex: 1;
}

.edit-sidebar {
  width: 636px;
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  position: absolute;
  left: 417px;
  top: 0;

}
.wiki_cancel-button{
  border: none;
  background: none;
}
.edit-title{
  font-size: 18px;
  font-family: nunito_semibold;
}

.edit-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.edit-form label {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.edit-form input[type="text"] {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-buttons {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.save-btn {
  background: #4CAF50;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
}

.cancel-btn {
  background: #f44336;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
}
.wiki-name{
  font-size: 20px;
  font-family: nunito_bold;
  color: #022B94;
}
.wiki-details{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #FAFAFA;
  border-radius: 5px;
  max-width: 247px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.wiki_details_img{
  width: 42px;
  height: 42px;
}
.wiki-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  
  gap: 12px;
}
.wiki-actions button{
  background: none;
  border: none;
  cursor: pointer;
  padding: 0px;

}
.wiki_add-btn{
  display: flex;
  background-color: none;
}
.knowledge-base-page{
  margin-top: 70px;
}
.wiki-actions{
  display: flex;
  flex-direction: row;
 gap: 13px;
  background-color: #FAFAFA;
  padding-top: 16px;
padding-bottom: 16px;
padding-left: 11.5px;
padding-right: 11.5px;
border-radius: 5px;
}
.add_news_label{
  font-size: 16px;
  font-family: nunito;
}
.custom-file-label{
  font-size: 16px;
  font-family: nunito;
}
@media (max-width:1098px) {
  .form_modal {
    max-width: 60rem;
    width: 100%;
    margin-right: 30px;

  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}