body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@font-face {
  font-family: gothampro;
  src: local(gothampro), url(./fonts/gothampro_medium.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: gothampro;
  src: local(gothampro_light), url(./fonts/gothampro_light.ttf ) format('truetype');
  font-weight: light;
}

@font-face {
  font-family: mulishregular;
  src: local(mulishregular), url(./fonts/Mulish-Regular.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: nunito;
  src: local(nunito), url(./fonts/Nunito-Regular.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: nunito_semibold;
  src: local(nunito), url(./fonts/Nunito-SemiBold.ttf) format('truetype');
  font-weight: bold;

}
@font-face {
  font-family: nunito_bold;
  src: local(nunito), url(./fonts/Nunito-Bold.ttf) format('truetype');
  font-weight: bold;

}

@font-face {
  font-family: nunito_extrabold;
  src: local(nunito), url(./fonts/Nunito-ExtraBold.ttf) format('truetype');
  font-weight: bold;

}

@font-face {
  font-family: nunito_medium;
  src: local(nunito), url(./fonts/Nunito-Medium.ttf) format('truetype');
  font-weight: medium;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}